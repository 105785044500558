import React, {useEffect, useState, useContext} from "react";
import {ReferenceDataContext} from "../businessLogic/ReferenceDataContext";
import {navigate} from "gatsby";
import {
    Badge, Button,
    Card,
    CardImg,
    CardText,
    CardTitle,
    Col,
    Container,
    Input,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, FormGroup,
    Dropdown, DropdownMenu, DropdownToggle,
    DropdownItem
} from "reactstrap";

import "../Admin.css";
import Product from "../businessLogic/Product";

const SubmitStatus = {
    SUBMITTING: "submitting",
    NOT_SUBMITTED: "notSubmitted",
    SUCCESS: "success",
    FAILURE: "failure",
    PHOTO_FAILURE: "photoFailure"
};

function ProductCard({ product }) {
    const [photoUrl, setPhotoUrl] = useState("")

    useEffect(() => {
        if(typeof window !== "undefined") {
            const {downloadFile} = require("../businessLogic/Firebase");
            downloadFile(product.imageUrl).then((url) => setPhotoUrl(url))
        }
    }, [product])

    return (
        <Container className={"product-item-card-container"}>
            <Card className={"product-item-card"}>
                <p className={"product-item-card-id"}>CODE: {product.code}</p>
                <Badge className={"product-item-card-badge" + (product.condition.toLowerCase() === "new" ? " alert-success" : " alert-warning")}
                       pill>
                    {product.condition}
                </Badge>
                {
                    photoUrl !== "" ?
                        <CardImg top width="100%" src={photoUrl} alt={product.name} />
                        : ""
                }
                <Card body>
                    <CardTitle>
                        <Row>
                            <Col xs={9}>
                                {product.name}
                            </Col>
                            <Col className="text-right" xs={3}>
                                {"$" + product.cost}
                            </Col>
                            <Col xs={6} className={"product-item-card-subtitle"}>
                                {product.type}
                            </Col>
                            { product.isReplaceable ?
                                <Col xs={6} className={"product-item-card-subtitle-small text-danger text-right"}>
                                    {"(Trade-in with up to %" + product.replaceDiscount + " discount)"}
                                </Col> : ""
                            }
                        </Row>
                    </CardTitle>
                    <CardText className={"text-dark"}>
                        {product.description}
                    </CardText>
                    <Row className={"mt-1"} hidden={!product.isReplaceable}>
                        <Col>
                            <p className={"text-success"}>This item is marked as replaceable.</p>
                        </Col>
                    </Row>
                </Card>
            </Card>
        </Container>
    );
}

export default function Admin() {
    const [products, setProducts] = useState([])

    const [updatingId, setUpdatingId] = useState("")
    const [updatingCode, setUpdatingCode] = useState("")
    const [updatingName, setUpdatingName] = useState("")
    const [updatingType, setUpdatingType] = useState("")
    const [updatingDesc, setUpdatingDesc] = useState("")
    const [updatingCost, setUpdatingCost] = useState(0)
    const [updatingCondition, setUpdatingCondition] = useState("New")
    const [updatingIsReplaceable, setUpdatingIsReplaceable] = useState(false)
    const [updatingReplaceDiscount, setUpdatingReplaceDiscount] = useState(0)
    const [updatingImageUrl, setUpdatingImageUrl] = useState("")
    const [updatingImage, setUpdatingImage] = useState(undefined)

    const [photo, setPhoto] = useState(undefined)

    const [isDropDownOpen, setIsDropdownOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [status, setStatus] = useState(SubmitStatus.NOT_SUBMITTED)

    const { firebaseApp } = useContext(ReferenceDataContext)

    useEffect( () => {
        async function init() {
            if(typeof window !== "undefined" && firebaseApp && status === SubmitStatus.NOT_SUBMITTED) {
                const {isLoggedIn, getProducts} = require("../businessLogic/Firebase");

                if (isLoggedIn() === false) {
                    await navigate("/login", { replace: true })

                    return
                }

                setProducts(await getProducts())
            }
        }

        init().then()
    }, [firebaseApp, status])

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState)
        setStatus(SubmitStatus.NOT_SUBMITTED)

        setUpdatingId("")
        setUpdatingCode("")
        setUpdatingName("")
        setUpdatingType("")
        setUpdatingDesc("")
        setUpdatingCost(0)
        setUpdatingCondition("New")
        setUpdatingIsReplaceable(false)
        setUpdatingReplaceDiscount(0)
        setUpdatingImageUrl("")
        setUpdatingImage(undefined)
        setPhoto(undefined)
    }

    const setUpdatingProduct = (p) => {
        setUpdatingId(p.id)
        setUpdatingCode(p.code)
        setUpdatingName(p.name)
        setUpdatingType(p.type)
        setUpdatingDesc(p.description)
        setUpdatingCost(p.cost)
        setUpdatingCondition(p.condition)
        setUpdatingIsReplaceable(p.isReplaceable)
        setUpdatingReplaceDiscount(p.replaceDiscount)
        setUpdatingImageUrl(p.imageUrl)

        if(typeof window !== "undefined") {
            const {downloadFile} = require("../businessLogic/Firebase");
            downloadFile(p.imageUrl).then((url) => setPhoto(url))
        }

        setIsModalOpen(true)
    }

    const onFileChange = (e) => {
        if(e.target.files[0].size > 10485760) {
            return
        }

        setUpdatingImage(e.target.files[0])
        setPhoto(URL.createObjectURL(e.target.files[0]))
    } ;

    const remove = () => {
        if(typeof window !== "undefined" && firebaseApp) {
            const {removeProduct} = require("../businessLogic/Firebase");

            let agreed = window.confirm("Are you sure to delete this item?");

            if(agreed) {
                setStatus(SubmitStatus.SUBMITTING)

                removeProduct(updatingId, updatingImageUrl).then(res => {
                    setStatus(res ? SubmitStatus.SUCCESS : SubmitStatus.FAILURE)
                })
            }
        }
    }

    const submit = () => {
        if(typeof window !== "undefined" && firebaseApp) {
            const {addProduct, updateProduct, uploadProductImage} = require("../businessLogic/Firebase");
            setStatus(SubmitStatus.SUBMITTING)
            uploadProductImage(updatingImage, updatingImageUrl).then(url => {
                if(url === undefined) {
                    setStatus(SubmitStatus.PHOTO_FAILURE)
                    return
                }

                const product = new Product(
                    updatingId,
                    updatingCode,
                    updatingName,
                    updatingDesc,
                    updatingType,
                    url,
                    updatingCost,
                    updatingCondition,
                    updatingIsReplaceable,
                    updatingReplaceDiscount)

                if(updatingId === "") { // add new
                    addProduct(product).then(res => {
                        setStatus(res ? SubmitStatus.SUCCESS : SubmitStatus.FAILURE)
                    })
                } else {
                    updateProduct(product).then(res => {
                        setStatus(res ? SubmitStatus.SUCCESS : SubmitStatus.FAILURE)
                    })
                }
            })
        }
    }

    return (
        <>
            <Container fluid>
                <Row className={"mt-3"}>
                    <Col xs={6}>
                        <h3 className={"products-title-box"}>All Products</h3>
                    </Col>
                    <Col xs={6} className="text-right">
                        <Button color={"success"} onClick={() => setIsModalOpen(true)}>Add Product</Button>
                    </Col>
                    {
                        products.map((p, index) =>
                            <Col key={index} xs={12} md={6} lg={4} onClick={() => setUpdatingProduct(p)}>
                                <ProductCard product={p} />
                            </Col>
                        )
                    }
                </Row>
            </Container>
            <Modal isOpen={isModalOpen} toggle={toggleModal} size={"xl"} scrollable className={"modal"}>
                <ModalHeader toggle={toggleModal}>
                    {
                        updatingId === "" ? "Add a new product" :
                            "Edit Product - " + updatingName
                    }
                </ModalHeader>
                <ModalBody className={"body"}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"code"}>Code: </label>
                                    <Input id={"code"} type={"text"} placeholder={"Code"} defaultValue={updatingCode}
                                           onBlur={(e) => setUpdatingCode(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"name"}>Name: </label>
                                    <Input id={"name"} type={"text"} placeholder={"Name"} defaultValue={updatingName}
                                           onBlur={(e) => setUpdatingName(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"type"}>Type: </label>
                                    <Input id={"type"} type={"text"} placeholder={"Type"} defaultValue={updatingType}
                                           onBlur={(e) => setUpdatingType(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"desc"}>Desc: </label>
                                    <Input id={"desc"} type={"text"} placeholder={"Description"} defaultValue={updatingDesc}
                                           onBlur={(e) => setUpdatingDesc(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"cost"}>Cost: </label>
                                    <Input id={"cost"} type={"number"} placeholder={"Cost"} defaultValue={updatingCost}
                                           onBlur={(e) => setUpdatingCost(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Row className={"align-items-center"}>
                                    <Col>
                                        <FormGroup className={"form-group"}>
                                            <label htmlFor={"condition"}>Condition: </label>
                                            <Dropdown id={"condition"} group isOpen={isDropDownOpen} toggle={() => setIsDropdownOpen(state => !state)} className="dropdown">
                                                <DropdownToggle caret className="dropdownToggle">
                                                    {updatingCondition}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => {setUpdatingCondition("New")}}>New</DropdownItem>
                                                    <DropdownItem onClick={() => {setUpdatingCondition("Restored")}}>Restored</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                    <Col className={"mt-3"}>
                                        <input type="checkbox" id="replace" name="replace"
                                               checked={updatingIsReplaceable}
                                               onChange={() => setUpdatingIsReplaceable(prevState => !prevState)}/>
                                        <label htmlFor={"replace"}><span className={"ml-2"} />Eligible for trade-in.</label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"replaceDiscount"}>Max discount: </label>
                                    <Input id={"replaceDiscount"} type={"number"} disabled={!updatingIsReplaceable}
                                           placeholder={"Replace Discount"} defaultValue={updatingReplaceDiscount}
                                           onBlur={(e) => setUpdatingReplaceDiscount(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"image"}>Image: </label>
                                    <Row id={"image"}>
                                        <Col>
                                            <div className="fileUploadContainer">
                                                <label htmlFor="fileUpload" className="fileUpload">
                                                    <i className="icofont-cloud-upload icofont-5x"/>
                                                    Upload Image
                                                </label>
                                                <input type="file" id="fileUpload" onChange={onFileChange}
                                                       accept="image/vnd.sealedmedia.softseal.jpg, image/jpeg, 	image/vnd.sealed.png, image/png, .jpg, .jpeg, .png"
                                                />
                                            </div>
                                            <p>{updatingImage && updatingImage.name}</p>
                                        </Col>
                                        <Col>
                                            { photo && <img src={photo} alt={"Uploaded"} className={"product-photo"} /> }
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                    {
                        updatingId !== "" &&
                        <Button color={"default"} size={"sm"} className={"text-danger delete-btn"}
                                disabled={status === SubmitStatus.SUCCESS} onClick={remove}>
                            Delete item
                        </Button>
                    }

                    <Modal isOpen={status === SubmitStatus.SUBMITTING} size={"xs"} className={"modal"} fade={false}>
                        <ModalBody>
                            is Loading ...
                        </ModalBody>
                    </Modal>
                </ModalBody>
                <ModalFooter>
                    <p className={status === SubmitStatus.SUCCESS ? "text-success" : "text-danger"}>
                        {
                            status === SubmitStatus.SUCCESS ? "Request completed successfully!" :
                                status === SubmitStatus.FAILURE ? "Request was failed to complete! Try again!" :
                                    status === SubmitStatus.PHOTO_FAILURE ? "Failed to upload image! Try again!" :
                                        ""
                        }
                    </p>
                    <Button color={"default"} onClick={toggleModal}>Close</Button>
                    <Button color={"primary"} onClick={submit} disabled={status === SubmitStatus.SUCCESS}>
                        {updatingId === "" ? "Add new" : "Save changes"}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}